import React from "react";
import { Row, Col } from "react-bootstrap";

import balloon from "../../images/balloon-screens.png";

import schlafen from "../../images/schlafen-screens.png";

export default function Products(props) {
  return (
    <>
      <Row className="mb-5">
        <Col md={6}>
          <img src={balloon} alt="balloon" className="productImg" />
        </Col>
        <Col md={6} className="mt-3">
          <h2>Balloon – Meditation für alle</h2>
          <p>
            Stress abbauen und gelassener werden. Meditationsforscher und
            Neurowissenschaftler Dr. Boris Bornemann leiht unserer
            Achtsamkeits-App Balloon nicht nur seine Stimme. Er stellt auch
            sicher, dass die Kurse und Übungen wissenschaftlich Hand und Fuß
            haben und wirklich halten, was sie versprechen. Boris nimmt dich mit
            auf eine Reise in die Welt der Achtsamkeit.
          </p>
          <p className="partner">
            Partner: <span>Flow, Brigitte, stern, Eltern</span>
          </p>
          <a className="mt-4 btn btn-primary" href="https://www.balloonapp.de/">
            Mehr Infos zum Projekt
          </a>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={6}>
          <img src={schlafen} alt="schlafen" className="productImg" />
        </Col>
        <Col md={6} className="mt-3">
          <h2>7Schläfer – Einfach schlafen</h2>
          <p>
            Entdecke, wie guter Schlaf dein Leben verändert. Schlafforscher
            Albrecht Vorster zeigt dir die wichtigsten Zutaten für einen
            gesunden Schlaf. In 7 Wochen Audiokurs bringst du sie Schritt für
            Schritt mit deinen eigenen Gewohnheiten und Bedürfnissen zusammen
            und wirst so zum Schlafexperten in eigener Sache. Es wird Zeit für
            geruhsame Nächte und erfüllte Tage.
            <br />
            Jetzt ausprobieren!
          </p>
          <a className="mt-4 btn btn-primary" href="https://7schlaefer.app/">
            Mehr Infos zum Projekt
          </a>
        </Col>
      </Row>
    </>
  );
}
