import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/templates/layout"
import SEO from "../components/templates/seo"
import Hero from "../components/organisms/hero"

import Products from "../components/organisms/products"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={[`MissionMe`]} />
    <Container fluid="md">
      <Hero
        showDots
        headingPreHeadline="Wir"
        headingHeadline="Apps, die dein Leben leichter machen."
        introHeadline="Die MissionMe-Formel"
        introText={'Wir unterstützen Menschen auf dem Weg zu ihrem besseren Ich. Wir entwickeln Produkte, die Wegbegleiter sind. Wir fragen unsere Nutzer wer sie heute sind und wer sie morgen sein wollen - und finden heraus, wie wir sie auf dem Weg zum Ziel am besten unterstützen können.\n\nMit Experten aus der Wissenschaft und praktischen Erfahrungen begleiten wir unsere Nutzer auf ihrem Weg, greifen beim ersten Schritt unter die Arme, fangen auf, wenn es mal schwierig wird. Wir helfen neue Gewohnheiten zu entwickeln, Ziele zu erreichen und dabei achtsamer mit Körper und Geist umzugehen. Das alles (und viel Liebe) steckt in unseren Apps, die jeder auf seinem Smartphone immer bei sich tragen kann.'}
        introImg={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.151 395.848">
        <defs>
            <clipPath id="a">
                <rect width="538.151" height="395.848" fill="none" />
            </clipPath>
        </defs>
        <g clipPath="url(#a)">
            <g transform="translate(-0.128 -0.271)">
                <path d="M165.7,165.829,82.916,143.651.128,121.471l60.6-60.6,60.6-60.6,22.18,82.784Z" fill="#009e7b" className="triangle" />
                <circle cx="125.124" cy="125.124" r="125.124" transform="translate(63.353 70.401)" fill="none" />
                <path
                    d="M188.477,320.65A125.124,125.124,0,1,1,313.6,195.525,125.267,125.267,0,0,1,188.477,320.65Zm0-207.392a82.267,82.267,0,1,0,82.267,82.267A82.361,82.361,0,0,0,188.477,113.258Z"
                    fill="#f39200" className="donut" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(189.329 204.435)" fill="#009e7b" className="pointIndex"/>
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(209.807 196.209)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(230.286 187.982)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(250.764 179.756)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(271.242 171.529)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(291.721 163.303)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(312.199 155.076)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(332.678 146.849)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(353.156 138.623)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(373.635 130.396)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(197.555 224.914)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(218.034 216.687)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(238.512 208.461)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(258.991 200.234)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(279.469 192.008)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(299.947 183.781)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(320.426 175.554)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(340.904 167.328)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(361.383 159.101)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(381.861 150.875)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(205.782 245.392)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(226.26 237.166)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(246.739 228.939)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(267.217 220.713)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(287.696 212.486)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(308.174 204.259)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(328.652 196.033)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(349.131 187.806)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(369.609 179.58)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(390.088 171.353)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(214.008 265.871)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(234.487 257.644)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(254.965 249.418)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(275.444 241.191)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(295.922 232.964)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(316.401 224.738)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(336.879 216.511)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(357.357 208.285)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(377.836 200.058)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(398.314 191.832)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(222.235 286.349)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(242.713 278.123)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(263.192 269.896)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(283.67 261.669)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(304.149 253.443)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(324.627 245.216)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(345.106 236.99)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(365.584 228.763)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(386.062 220.537)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(406.541 212.31)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(230.462 306.828)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(250.94 298.601)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(271.418 290.374)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(291.897 282.148)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(312.375 273.921)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(332.854 265.695)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(353.332 257.468)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(373.811 249.242)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(394.289 241.015)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(414.767 232.788)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(238.688 327.306)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(259.167 319.079)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(279.645 310.853)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(300.123 302.626)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(320.602 294.4)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(341.08 286.173)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(361.559 277.947)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(382.037 269.72)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(402.516 261.493)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(422.994 253.267)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(246.915 347.784)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(267.393 339.558)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(287.872 331.331)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(308.35 323.105)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(328.828 314.878)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(349.307 306.652)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(369.785 298.425)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(390.264 290.198)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(410.742 281.972)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(431.221 273.745)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(255.141 368.263)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(275.62 360.036)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(296.098 351.81)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(316.577 343.583)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(337.055 335.357)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(357.533 327.13)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(378.012 318.903)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(398.49 310.677)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(418.969 302.45)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(439.447 294.224)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(263.368 388.741)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(283.846 380.515)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(304.325 372.288)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(324.803 364.062)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(345.282 355.835)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(365.76 347.608)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(386.238 339.382)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(406.717 331.155)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(427.195 322.929)" fill="#009e7b" className="pointIndex" />
                <circle cx="3.689" cy="3.689" r="3.689" transform="translate(447.674 314.702)" fill="#009e7b" className="pointIndex" />
                <path d="M368.425,175.869a84.927,84.927,0,0,0,169.854,0Z" fill="#ea504c" className="semicircle" />
            </g>
        </g>
    </svg>}
      />
      <Row className="mt-5">
        <Col md={12}>
          <Products/>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage
